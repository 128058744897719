.pi-dollar {
  content: url("https://devqa.anytimecollect.com/(S(jnqdnpoysq1ek1r2hwauji15))/Images/tbExpectedPayment.png");
}

.pi-phone {
  content: url("https://devqa.anytimecollect.com/(S(jnqdnpoysq1ek1r2hwauji15))/Images/tbPhone.png");
}

.pi-custom {
  color: #178087;
  font-size: 18px;
  font-weight: 600;
  margin: 2px;
}

.p-inputtext {
  padding: 0.25rem 0.5rem;
}

th.p-filter-column .p-checkbox.p-component {
  margin-left: 0.1rem;
}

.p-dropdown-trigger-icon.p-clickable.pi.pi-chevron-down {
  color: #ffffff !important;
}

.p-dropdown-trigger-icon {
  width: 10px;
}

.filterTemplate .dropdown .p-dropdown-trigger-icon.p-clickable {
  font-size: 10px !important;
  color: black !important;
}

.p-tree {
  padding: 0.8rem 0.1rem;
  border-radius: 0;
}

.p-treenode-content.p-treenode-selectable {
  padding: 3px !important;
}

.p-treenode-content {
  cursor: pointer;
}

.p-paginator-element.p-link.p-disabled:not(.ellips) {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.p-paginator-page.p-paginator-element.p-link.p-disabled.ellips {
  background-color: transparent !important;
}

.p-datatable {
  background-color: #ffffff;
}

.fixed-header .p-datatable-thead {
  position: -webkit-sticky;
  position: sticky !important;
  top: 0 !important;
  border: 1px solid #dbe0e3 !important;
  z-index: 1 !important;
}

.fixed-header .p-paginator.p-paginator-bottom {
  border: 1px solid #0f3044;
}

.p-datatable .p-datatable-thead > tr > th {
  background: #dbe0e3;
  font-size: 0.9rem;
  padding: 0.4rem;
}

.p-datatable .p-datatable-tbody > tr > td.overflow-ellipse {
  padding: 0.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 325px;
}

.p-datatable th.p-state-active {
  background: #186ba0;
  color: #ffffff;
}

.p-sortable-column.p-highlight {
  background-color: #c1c1c1 !important;
}

.p-paginator-page.p-paginator-element.p-link.p-highlight {
  border-radius: 4px;
  background: transparent !important;
  border: 1px solid #ffffff !important;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  padding: 0;
  min-width: 1.5rem;
  outline: none;
  box-shadow: none;
}

.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled:not(.assign-to-dd) {
  background-color: #7d4ed8;
  color: #ffffff;
  border-radius: 0;
  align-items: center;
}

.p-inputtext.p-component.ml-1 {
  width: 3rem;
  height: 2rem;
}

.p-dropdown-label.p-inputtext {
  color: #ffffff !important;
}

/* Table Footer */
.p-paginator.p-component.p-paginator-bottom {
  background-color: #0f3044;
  padding: 5px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
}

.p-paginator-prev.p-link,
.p-paginator-page.p-link,
.p-paginator-next.p-link,
.p-paginator-first.p-paginator-element.p-link,
.p-paginator-last.p-paginator-element.p-link,
.mx-3 {
  background-color: transparent !important;
  border: none !important;
  color: #ffffff !important;
}

.p-autocomplete-panel {
  max-height: 250px !important;
}

.p-dropdown-panel.p-component.p-connected-overlay-enter-done {
  z-index: 9999999999 !important;
}

.p-dropdown-item.p-highlight {
  background: #7d4ed8 !important;
}

.p-tree .p-treenode-children {
  padding: 0 0 0 0.5rem !important;
}

.pi-filter {
  margin: 7px 0 !important;
}

.p-tabview-nav {
  flex-wrap: wrap;
}

.p-tabview-nav,
.p-tabview-nav-link,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border: none !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: #bbe9ec !important;
  border-radius: 4px;
}

.p-tabview-panels {
  padding: 1.25rem 0 0 0 !important;
}

.p-column-header-content {
  text-transform: capitalize;
}

.p-datepicker-trigger {
  background: #7d4ed8 !important;
}

.p-datatable-thead {
  height: 45px !important;
  background: #dbe0e3 !important;
}

.p-datatable-thead,
.p-datatable-thead tr,
.p-datatable-thead tr td,
.p-datatable-thead tr th {
  border: none !important;
}

.p-datatable.p-datatable-sm .p-datatable-tfoot > tr {
  background-color: lightgray !important;
}

.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  border: none !important;
}

.p-tree-container {
  height: 525px;
}

.p-tree-container {
  overflow-x: hidden;
}

.p-treenode-label,
.overflow-ellipse {
  width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom-pagination {
  background-color: #0f3044;
  padding: 4px;
}

.p-contextmenu {
  width: 200px;
  box-shadow: 0 3px 3px rgba(122, 122, 122, 0.7);
}

.p-contextmenu .p-menuitem a {
  padding: 18px 18px 18px 5px !important;
}

.p-contextmenu .p-menuitem a:hover {
  color: #333;
  background: #bceff8 !important;
}

.w-97 {
  width: 97%;
}

.date-picker-custom input,
.date-picker-custom button,
.input-text-custom {
  height: 32px;
  line-height: 32px;
}

.date-picker-custom.w-118-px input {
  width: 118px !important;
}

.p-dropdown-item.p-dropdown-item-empty {
  height: 29.99px !important;
}

.assign-to-dd {
  height: 30px;
  align-items: center;
}

.p-button.p-component.p-fileupload-choose {
  border: none;
  background: #7d4ed8;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
