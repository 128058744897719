/* @import "primereact/resources/themes/lara-light-indigo/theme.css"; */
@import 'primereact/resources/themes/bootstrap4-light-purple/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'bootstrap/dist/css/bootstrap.css';
@import 'primeicons/primeicons.css';
@import './components/css/bootstrap-custom.css';
@import './components/css/prime-custom.css';
@import './components/css/icons.css';
@import './components/css/react-select-custom.css';
@import './components/css/spinner.css';
@import './components/navbar//navbar.css';
@import './components/common/css/responsive.css';
@import './components/css/mui-custom.css';

html {
  --scrollbarBG: #f1f1f1;
  --thumbBG: #3c596d;
  font-size: 13px;
  padding: 0;
  margin: 0;
  height: 100%;
}

html,
body,
#main {
  height: 100%;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--thumbBG);
  border-top: 1px solid var(--scrollbarBG);
  border-bottom: 1px solid var(--scrollbarBG);
  border-left: 1px solid var(--scrollbarBG);
}

/* Styles when the scrollbar thumb is hovered */
::-webkit-scrollbar-thumb:hover {
  background: #426b84;
}

.p-datatable-resizable > .p-datatable-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-top: 1px solid var(--scrollbarBG);
  border-bottom: 1px solid var(--scrollbarBG);
  border-left: 1px solid var(--thumbBG) !important;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Work Sans', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.width-100-px {
  width: 100px;
}

.width-150-px {
  width: 150px;
}

.width-50-px {
  width: 50px !important;
}

.width-250-px {
  width: 250px;
}

.noHover:hover {
  background-color: unset !important;
  color: #ffffff !important;
}

.noBorder {
  border: none;
}

.mail-not-read-table {
  width: 100%;
}

.font-22-px {
  font-size: 22px;
}

.font-30-px {
  font-size: 30px;
}

.h-30-px {
  height: 30px;
}

.h-450-px {
  height: 450px;
}

.h-650-px {
  height: 650px;
}

.h-550-px {
  height: 550px;
}

.dropdown-paging {
  outline: none !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.filterTemplate .dropdown,
.filterTemplate .actionButton {
  background: transparent !important;
  border: none;
  color: #676767 !important;
}

.filterTemplate .actionButton:hover {
  background-color: #b8b8b8 !important;
  border-radius: 4px;
}

.filterTemplate .dropdown .p-dropdown-label {
  color: #4fbfd2 !important;
  padding-right: 2px;
}

.filterTemplate .dropdown .p-dropdown-label:hover {
  color: black !important;
}

.filterTemplate .dropdown .p-dropdown-trigger {
  width: max-content;
}

.btn-lightgreen {
  border-radius: 4px;
  color: #000 !important;
  background: #bbe9ec !important;
  border: none;
}

.btn-lightgreen.btn-transparent {
  background: transparent !important;
}

.btn-icon {
  line-height: 20px;
  cursor: pointer;
  padding: 5px;
  background: #ffffff !important;
  color: #178087 !important;
  border: none;
}

.bg-purple {
  background: #7d4ed8 !important;
}

.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}

span,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: 100%;
}

.h-80-px {
  height: 80px;
}

.text-error {
  color: red !important;
}

.text-success {
  color: green !important;
}

#mail-not-read-table
  .p-filter-column
  .p-dropdown-label.p-inputtext.p-placeholder,
#mail-not-read-table
  .p-filter-column
  .p-dropdown-trigger-icon.p-clickable.pi.pi-chevron-down {
  color: #000000 !important;
}

.p-datatable-tbody tr td {
  padding: 6px 7px 3px !important;
}

#mail-not-read-table .custNameDropdown .p-placeholder,
.blueLink {
  border-style: none;
  color: #178087 !important;
  text-decoration: underline;
  font-weight: 500 !important;
  letter-spacing: -0.09px !important;
  line-height: 12px !important;
  background-color: inherit;
}

#mail-not-read-table .custNameDropdown .p-placeholder,
.blueLink,
.blueLink:not(.btn-blue-link) {
  font-size: 13px !important;
}

.btn-over-flow {
  max-width: 110px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

.btn-as-link {
  border: none !important;
  text-decoration: underline;
  padding: 0;
  margin: 0;
  color: #0d6efd !important;
  background: transparent !important;
}

.btn-as-link:hover,
.btn-as-link:focus {
  outline: none;
  box-shadow: none;
}

.p-highlight .blueLink {
  color: #ffffff !important;
}

#mail-not-read-table
  .p-dropdown.p-component.p-inputwrapper.bg-transparent.border-0
  .p-dropdown-trigger {
  display: none;
}

.p-paginator-pages .action-button i.disabled {
  color: grey !important;
  cursor: not-allowed;
}

.p-paginator-pages span {
  cursor: pointer;
}

.p-paginator-pages .page,
.p-paginator-pages .action-button {
  width: 30px;
  height: 30px;
  padding: 2px 8px;
  color: #ffffff;
  border: 1px solid transparent;
}

.p-paginator-pages .page.active {
  border: 1px solid white !important;
}

.p-paginator-pages .action-button i {
  font-size: 16px;
}

.p-paginator-pages .page.page-ellipse {
  pointer-events: none;
}

hr {
  border: 0px solid;
  height: 1px;
  background-color: #ccc;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
  margin-bottom: 15px;
}

.margin-top-40-px {
  margin-top: 40px;
}
.margin-top-24-px {
  margin-top: 24px;
}

.margin-right-5-px {
  margin-right: 5px;
}

.margin-bottom-5-px {
  margin-bottom: 5px;
}

.total-label,
.usually-pays-label {
  font-size: 1.1em;
  color: #5b6e6b;
  font-weight: normal;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.usually-pays-label {
  text-transform: none !important;
}

.customTooltip,
.statements-enable-disable-button {
  position: absolute;
  width: 226px;
  height: max-content;
  padding: 8px;
  border: 1px solid #178087 !important;
  border-radius: 2px !important;
  background-color: #ffffff !important;
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 50%) !important;
  bottom: -100px !important;
  right: 0 !important;
  z-index: 999999;
}

.customTooltip .text {
  background-color: #ffffff !important;
  color: black !important;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.statements-enable-disable-button {
  z-index: 99999;
  right: 0;
  background-color: #ffffff;
  list-style: none;
  padding: 0;
  width: 100px;
  bottom: -70px !important;
}

.statements-enable-disable-button li.item {
  padding: 8px 15px;
  cursor: pointer;
}

.statements-enable-disable-button li.item:hover {
  background-color: rgb(228, 222, 222);
}

table {
  width: 100%;
}

.text-editor-email-body {
  width: 100%;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0;
  border-left: 13px solid #d7d7d7;
  border-bottom: 4px solid #d7d7d7;
  border-right: 4px solid #d7d7d7;
  border-top: 4px solid #d7d7d7;
}

.text-editor-email-body > div {
  height: 100%;
  overflow: scroll;
  font-size: 16px;
  font-family: 'Times New Roman';
}

.h-max-content {
  height: max-content !important;
}

.w-max-content {
  width: max-content;
}

.w-fit-content {
  width: fit-content;
}

.h-fit-content {
  height: fit-content;
}

.w-150-px {
  width: 150px;
}

.w-200-px {
  width: 200px;
}

.w-300-px {
  width: 300px;
}

.w-350-px {
  width: 350px;
}

.w-400-px {
  width: 400px;
}

.w-450-px {
  width: 450px;
}

.w-832-px {
  width: 832px;
}

.w-120-px {
  width: 120px;
}

.h-35-px {
  height: 35px !important;
}

.h-40-px {
  height: 40px !important;
}

.w-70-vw {
  width: 70vw;
}

.w-auto {
  width: auto;
}

.max-width-60-per {
  max-width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}
.w-95 {
  width: 95%;
}

.w-98 {
  width: 98%;
}

.w-5-rem {
  width: 5rem;
}

.w-15-rem {
  width: 15rem;
}

.h-25-px {
  height: 25px;
}

.h-35-px {
  height: 35px;
}

.h-100-px {
  height: 100px;
}

.h-200-px {
  height: 200px !important;
}

.h-300-px {
  height: 300px !important;
}

.h-300-px {
  height: 350px;
}

.h-380-px {
  height: 380px;
}

.h-450-px {
  height: 450px;
}

.h-480-px {
  height: 480px;
}

.w-500-px {
  width: 500px;
}

.w-600-px {
  width: 600px;
}

.h-500-px {
  height: 500px;
}

.border-2-px-solid {
  border: 2px solid black;
}

.border-1-px-solid {
  border: 1px solid black;
}

.cursor-pointer {
  cursor: pointer !important;
}

.w-50-vw {
  width: 50vw;
}

.w-40-vw {
  width: 40vw;
}

.w-5 {
  width: 5%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60%;
}

.gap-2 {
  gap: 2px;
}

.gap-4 {
  gap: 4px;
}

.table-layout-fixed {
  table-layout: fixed;
}

.pl-3-rem {
  padding-left: 3rem;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto;
}

.border-collapse-seperate {
  border-collapse: separate;
}

.bg-white {
  background-color: #ffffff;
}

.float-right {
  float: right;
}

.bg-light-blue {
  background: #257eb3 !important;
}

.bg-light {
  background: #e6e6e6 !important;
}

.text-bold {
  font-weight: bold;
}

.bg-red {
  background-color: red;
}

.light-grey {
  background-color: #fafbfc;
}

.text-white {
  color: #ffffff;
}



.h-190-px {
  height: 190px !important;
}

.h-400-px {
  height: 400px;
}

.h-412-px {
  height: 412px;
}

.min-width-190-px {
  min-width: 190px !important;
}

.min-width-400-px {
  min-width: 400px !important;
}

.backdrop {
  width: 100% !important;
  height: 95% !important;
  height: -webkit-fill-available !important;
  width: -webkit-fill-available !important;
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #868484;
  position: absolute !important;
  z-index: 999999;
}

.min-width-fit-content {
  min-width: fit-content;
}

.ml-auto {
  margin-left: auto !important;
}

.active {
  background-color: #80cfdc;
}

.bg-light-grey {
  background: lightgrey;
}

.text-black {
  color: black !important;
}

.overflow-y-auto {
  overflow-y: auto;
}

.p-tree.p-component.files-folders {
  height: 300px;
}

.file-explorer-tree .p-tree-container {
  height: auto !important;
  max-height: 280px !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.w-955-px {
  width: 955px !important;
}

.w-430-px {
  width: 430px;
}

.w-330-px {
  width: 330px;
}

.payment-method-iframe {
  width: 832px;
  height: 100%;
  border: none;
}

.h-95 {
  height: 95%;
}

.bold {
  font-weight: bold;
}

.h-720-px {
  height: 720px;
}

.expand-collapse-customer-doc-files-tree,
.files-tree {
  transition: all 0.5s;
}

.expand-collapse-customer-doc-files-tree.bg-light-grey:hover {
  background-color: #0092ab !important;
}

.expand-collapse-customer-doc-files-tree.bg-light-grey:hover svg path {
  fill: #ffffff;
}

.w-0 {
  width: 0;
}

.w-8-px {
  width: 8px;
}

.transition-slow {
  transition: all 0.8s;
}

.transition-medium {
  transition: all 0.5s;
}

.transition-fast {
  transition: all 0.3s;
}

.navbar-and-content-wrapper {
  display: flex;
  min-height: 100vh;
}

.action-header-wrapper {
  padding: 5px;
}

.action-header-wrapper .btn-icon {
  margin: 2px;
}

.w-h-110-px {
  width: 110px;
  height: 110px;
}

.input-text-right {
  text-align: right;
}

/* Override bootstrap and prime react css which has conflicts  */
.MuiTablePagination-root p {
  margin-top: 1rem;
}

.tabs-content-wrapper {
  margin: 10px 0;
  padding: 5px;
}
.main-container {
  padding-left: 2px;
  padding-right: 2px;
}
.centered-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-icon {
  font-size: 5em;
  color: #178087;
  margin-top: 5px;
}

.sun-editor .se-resizing-bar .se-navigation{
  display: none;
}