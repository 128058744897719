/* Icons */
.pi-check-blue {
  content: url('../images/icons/icon-check-blue.png');
}

.pi-view {
  content: url('../images/icons/icon-view.png');
}

.pi-assign-mail {
  content: url('../images/icons/icon-assign-mail.png');
}

.pi-apply-filter {
  content: url('https://devqa.anytimecollect.com/(S(soauubf3qs5cvyo5z5wpv0br))/Images/LoadView.png');
}

.pi-clear-filter {
  content: url('https://devqa.anytimecollect.com/(S(soauubf3qs5cvyo5z5wpv0br))/Images/DeleteView.png');
}

.pi-save {
  content: url('../images/icons/svg/save-white.svg');
}

.pi-inbox {
  content: url('../images/icons/icon-inbox-yellow.png');
}

.pi-sent {
  content: url('../images/icons/icon-sent-items.png');
}

.pi-drafts {
  content: url('../images/icons/icon-drafts.png');
}

.pi-received-email {
  content: url('../images/icons/icon-recieved-email.png');
}

.pi-follow-up {
  content: url('../images/icons/icon-follow-up.png');
}

.pi-dispute {
  content: url('../images/icons/icon-dispute.png');
}

.pi-tagged-invoice {
  content: url('../images/icons/icon-tagged-invoice.png');
}

.pi-export {
  content: url('../images/icons/icon-export.png');
  margin: 1px 0 !important;
}

.pi-custom-refresh {
  content: url('../images/icons/icon-refresh.png');
  margin: 1px 0 !important;
}

.pi-best-fit {
  content: url('../images/icons/icon-best-fit.png');
  margin: 4px 0 !important;
}

.pi-setting {
  content: url('../images/icons/icon-setting.png');
  margin: 0 !important;
}

.pi-freeze-columns {
  content: url('../images/icons/icon-freeze-column.png');
  margin: 1px 0 !important;
}

.pi-alert {
  content: url('../images/icons/icon-alert.png');
}

.pi-edit {
  content: url('../images/icons/icon-edit.png');
}

.pi-report {
  content: url('../images/icons/icon-report.png');
}

.pi-sent-email {
  content: url('../images/icons/icon-sent-email.png');
}

.pi-email-resend {
  content: url('../images/icons/icon-email-resend.png');
}

.pi-email-error {
  content: url('../images/icons/svg/icon-email-error.svg');
}

.pi-email-queued {
  content: url('../images/icons/svg/icon-email-queued.svg');
}

.pi-warning {
  content: url('../images/icons/icon-alert-warning.gif');
}

.pi-read {
  content: url('../images/icons/icon-read.png');
}

.pi-unread {
  content: url('../images/icons/icon-unread.png');
}

.pi-communicate {
  content: url('../images/icons/icon-communicate.png');
}

.pi-activity {
  content: url('../images/icons/svg/communicate.svg');
  margin: 3px 0 !important;
}

.pi-received-email-unread {
  content: url('../images/icons/icon-recieved-email-unread.png');
}

.pi-received-email-read {
  content: url('../images/icons/icon-recieved-email-read.png');
}

.pi-followup-complete {
  content: url('../images/icons/svg/icon-followup-complete.svg');
}

.pi-followup-overdue {
  content: url('../images/icons/svg/followup-overdue.svg');
}

.pi-info {
  content: url('../images/icons/svg/info.svg');
  cursor: pointer;
}

.pi-phone-successful {
  content: url('../images/icons/svg/icon-phone-successful.svg');
}

.pi-phone-unsuccessful {
  content: url('../images/icons/svg/icon-phone-unsuccessful.svg');
}

.pi-exclamation-circle {
  color: #1b99d3 !important;
  font-size: 25px;
  width: 30px;
  height: 30px;
}

.pi-select-all {
  content: url('../images/icons/icon-select-all.png');
}

.pi-clear-all {
  content: url('../images/icons/icon-clear-all.png');
}

.pi-load-view {
  content: url('../images/icons/load-view.png');
}

.pi-delete-view {
  content: url('../images/icons/delete-view.png');
}

.pi-add-note {
  content: url('../images/icons/add-note.png');
}

.pi-payment {
  content: url('../images/icons/icon-payment.png');
  margin: 2px 0 !important;
}

.pi-setting-grey {
  content: url('../images/icons/setting.png');
}

.pi-attachment {
  content: url('../images/icons/icon-attachment.png');
}

.pi-voice-call {
  content: url('../images/icons/voice-call.png');
}

.pi-voice-call-successful {
  content: url('../images/icons/icon-voice-call-successful.png');
}

.pi-voice-call-unsuccessful {
  content: url('../images/icons/icon-voice-call-unsuccessful.png');
}

.pi-text {
  content: url('../images/icons/icon-text.png');
}

.pi-text-successful {
  content: url('../images/icons/icon-text-successful.png');
}

.pi-text-unsuccessful {
  content: url('../images/icons/icon-text-unsuccessful.png');
}

.pi-pdf {
  content: url('../images/icons/icon-pdf.png');
}

.pi-pdf-warning {
  content: url('../images/icons/icon-pdf-warning.png');
}

.pi-folder {
  content: url('../images/icons/icon-folder.png');
}

.pi-trash {
  content: url('../images/icons/svg/trash3.svg');
}

.pi-person-plus {
  content: url('../images/icons/svg/person-plus.svg');
}

.pi-question-mark-rounded-blue {
  content: url('../images/icons/gif/icon-question-mark-rounded-blue.gif');
}

.pi-lockstep {
  content: url('../images/icons/icon-lockstep.png');
  width: 23px;
  height: 23px;
}