h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

@media (min-width: 48rem) {
  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 2.25em;
  }

  p {
    font-size: 1.5em;
  }
}

span,
h1,
h2,
h3,
h4,
h5,
h6,
label,
td,
.btn,
input,
a,
p {
  font-size: 100%;
}

.nav-items-icon {
  width: 22px;
  height: 22px;
}

.header-com .header-com-search-bar {
  width: 22vw;
  font-size: 10px;
}

.navbar-and-content-wrapper .navbar-wrapper {
  flex: 0 0 1;
  width: 23%;
}

.layout-container {
  width: 77%;
  margin: 0 auto;
}

.navbar-and-content-wrapper .navbar-wrapper.min-nav-bar {
  width: 5%;
}

.enable-preview-feature-text,
.nav-user-name-holder {
  font-size: 14px;
}

.nav-items {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

@media screen and (width: 820px) {
  .navbar-and-content-wrapper .navbar-wrapper {
    flex: 0 0 1;
    width: 23%;
  }

  .layout-container {
    width: 77%;
    margin: 0 auto;
  }

  .navbar-and-content-wrapper .navbar-wrapper.min-nav-bar {
    width: 5%;
  }
}

@media screen and (width: 768px) {
  .navbar-and-content-wrapper .navbar-wrapper {
    flex: 0 0 1;
    width: 34%;
  }

  .layout-container {
    width: 66%;
    margin: 0 auto;
  }

  .nav-items {
    padding-left: 1rem;
  }
}

@media screen and (width: 912px) {
  .navbar-and-content-wrapper .navbar-wrapper {
    flex: 0 0 1;
    width: 32%;
  }

  .layout-container {
    width: 68%;
    margin: 0 auto;
  }

  .nav-items {
    padding-left: 1rem;
    padding-right: 0.5rem;
  }

  .navbar-and-content-wrapper .navbar-wrapper.min-nav-bar {
    width: 5%;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .header-com .header-com-search-bar {
    min-width: 35vw;
  }

  .navbar-and-content-wrapper {
    flex-direction: column;
  }

  .show-hide-navbar {
    display: block;
  }

  .navbar-and-content-wrapper .navbar-wrapper {
    width: 100%;
  }

  .navbar-collapse-btn-icon {
    display: none;
  }

  .layout-container {
    margin-top: 40px;
    width: 100%;
  }

  .side-nav-list {
    margin: 10px 0;
    height: auto;
    overflow: hidden auto;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 601px) and (max-width: 799px) {
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  td,
  .btn,
  input,
  a,
  p {
    font-size: 85%;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
  .enable-preview-feature-text,
  .nav-user-name-holder {
    font-size: 13px;
  }

  h5.side-nav-item-text {
    font-size: 12px;
  }

  .nav-items {
    padding-left: 1rem;
    padding-right: 0.5rem;
  }

  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  td,
  .btn,
  input,
  a,
  p {
    font-size: 90%;
  }
}

@media only screen and (min-width: 601px) and(max-width: 938px) {
  .nav-items li {
    width: 6vw;
  }

  .navbar-wrapper:not(.min-nav-bar) .p-accordion-header .overflow-ellipse {
    width: 58px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 900px) {
  .navbar-wrapper:not(.min-nav-bar) .p-accordion-header .overflow-ellipse {
    width: 65px;
  }
}

@media only screen and (min-width: 901px) and (max-width: 990px) {
  .navbar-wrapper:not(.min-nav-bar) .p-accordion-header .overflow-ellipse {
    width: 90px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .navbar-wrapper:not(.min-nav-bar) .p-accordion-header .overflow-ellipse {
    width: 110px;
  }

  .navbar-and-content-wrapper .navbar-wrapper.min-nav-bar {
    width: 4%;
  }
}

@media only screen and (min-width: 1200px) {
  .navbar-and-content-wrapper .navbar-wrapper.min-nav-bar {
    width: 3%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1269px) {
  .navbar-and-content-wrapper .navbar-wrapper {
    width: 30%;
  }

  .nav-items {
    padding-left: 1rem;
    padding-right: 0.5rem;
  }

  .layout-container {
    width: 70%;
    margin: 0 auto;
  }

  .navbar-and-content-wrapper .navbar-wrapper.min-nav-bar {
    width: 5%;
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1699px) {
  .navbar-and-content-wrapper .navbar-wrapper {
    width: 16%;
  }

  .nav-items {
    padding-left: 1rem;
    padding-right: 0.5rem;
  }

  .layout-container {
    width: 82%;
    margin: 0 auto;
  }

  .navbar-and-content-wrapper .navbar-wrapper.min-nav-bar {
    width: 2%;
  }
}

@media only screen and (min-width: 1700px) {
  .navbar-and-content-wrapper .navbar-wrapper {
    width: 15%;
  }

  .nav-items {
    padding-left: 1rem;
    padding-right: 0.5rem;
  }

  .layout-container {
    width: 82%;
    margin: 0 auto;
  }

  .navbar-and-content-wrapper .navbar-wrapper.min-nav-bar {
    width: 2%;
  }
}
