.bg-navbar {
  background-color: #0f3044;
  min-height: 100%;
}

.nav-logo {
  width: 208px;
}

.icon-container {
  padding: 0 24px;
  margin-bottom: 16px;
}

.login-name-container {
  margin-top: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 8px;
}

.logo-menu {
  margin-top: 16px;
  margin-bottom: 16px;
}
.logo-menu a:hover {
  background-color: initial !important;
}
.side-nav-list {
  margin: 10px 0;
  height: 650px;
  overflow: hidden auto;
}

.navbar-brand {
  padding: 7px 6px;
}

.minimize-icon {
  margin-top: 32px;
}

.nav-user-name-holder {
  max-width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 10px;
}

.nav-bar-comp .p-accordion .p-accordion-header .p-accordion-toggle-icon {
  position: absolute;
  right: 0.5em;
}

.nav-bar-comp
  .p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link,
.nav-bar-comp .p-accordion .p-accordion-header .p-accordion-header-link {
  background: inherit;
  border: none;
  color: white;
  padding: 0;
}

.nav-bar-comp
  .p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link:hover,
.nav-bar-comp
  .p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  background-color: inherit;
  color: white;
}

.nav-bar-comp .p-accordion .p-accordion-content {
  background-color: inherit;
  color: white;
  border: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.nav-bar-comp a {
  text-decoration: none;
}

.nav-active-link {
  background-color: #0a212f;
}

.nav-bar-comp .count-badge {
  background-color: #ef3c6b;
  min-width: 25px;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  border-radius: 2px;
}

.nav-bar-comp h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.nav-bar-comp .nav-bar {
  overflow-y: auto;

  overflow-x: hidden;
}

.navbar-hide .p-accordion-toggle-icon {
  display: none;
}

.min-nav-bar {
  min-width: 50px;
  width: 5%;
}

.nav-bar-comp .preview-container hr {
  border: 1px solid;
}

.enable-preview-wrapper {
  flex: 1;
}

.show-hide-navbar {
  display: none;
}


.ps-menu-root .ps-menu-label {
  font-size: 15px;
  font-weight: 500;
}