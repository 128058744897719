.dialog-title__text {
    color: #0f3044;
    font-weight: 500;
}

.mui-tabs-content {
    background-color: #ffffff;
    min-height: 340px;
}

.MuiTabScrollButton-root.MuiTabScrollButton-horizontal.Mui-disabled {
    opacity: 0.3;
}
