.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.275rem;
  --bs-btn-font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

.btn + .btn {
  margin: 0 5px;
}

.btn-purple {
  border-radius: 0;
  color: #ffffff !important;
  background: #7d4ed8 !important;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}
